<template>
  <ui-information-card
    v-if="display"
    :infos="infos"
    :tables="tables"
    @save="onSave"
  >
    <template #title>
      {{ $t('users.informations', { name: user.firstname + ' ' + user.lastname }) }}
    </template>
    <template #subtitle>
      {{ $t('created-at', { date: getUserCreatedAt(user) }) }}
    </template>
  </ui-information-card>
  <ui-popin 
    v-if="addClient.display"
    :display-save-button="!_isEmpty(addClient.selected)"
    @close="addClient.display = false"
    @save="onSaveAddClient"
  >
    <h1 class="text-lg capitalize-first mb-4">
      {{ $t('clients.add') }}
    </h1>
    <select-client 
      :list="addClient.list"
      @select="addClient.selected = $event"
    />
  </ui-popin>
  <ui-popin 
    v-if="addEntity.display"
    :display-save-button="!_isEmpty(addEntity.selected)"
    @close="addEntity.display = false"
    @save="onSaveAddEntity"
  >
    <h1 class="text-lg capitalize-first mb-4">
      {{ $t('entities.add') }}
    </h1>
    <select-entity 
      :list="addEntity.list"
      @select="addEntity.selected = $event"
    />
  </ui-popin>
</template>

<script>
import clientsMixin from '../assets/mixins/clients'
import usersMixin from '../assets/mixins/users'

import UiInformationCard from '../components/ui-information-card.vue'
import UiPopin from '../components/ui-popin.vue'
import SelectClient from '../components/select-client.vue'
import SelectEntity from '../components/select-entity.vue'

export default {
  title () {
    return this.$ctc('user', 1)
  },
  mixins: [clientsMixin, usersMixin],
  components: {
    UiInformationCard,
    UiPopin,
    SelectClient,
    SelectEntity
  },
  data () {
    return {
      display: false,
      user: {},
      infos: [],
      tables: [],
      clients: [],
      entities: [],
      addClient: {
        display: false,
        list: [],
        selected: {}
      },
      addEntity: {
        display: false,
        list: [],
        selected: {}
      }
    }
  },
  computed: {
    userId () {
      return this.$route.params.id
    },
    clientsTable () {
      return this.getTable('clients')
    },
    entitiesTable () {
      return this.getTable('entities')
    },
    userRoles () {
      return this.$store.getters.getRoles
    }
  },
  async created () {
    try {
      this.user = await this.fetchUser(this.userId)
      this.clients = this.user.clients
      this.entities = this.user.clients.map((client) => {
        client.entities.forEach((entity) => {
          entity.clientId = client.clientId
        })

        return client.entities
      }).flat()

      this.initInfos()
      this.initTables()

      this.display = true
    } catch (err) {
      this.$notify.error()
    }
  },
  methods: {
    getTable (key) {
      return this.tables.find(table => key === table.key)
    },
    initInfos () {
      const { user } = this
      
      const infos = [
        {
          key: 'lastname',
          title: this.$t('lastname'),
          value: user.lastname
        },
        {
          key: 'firstname',
          title: this.$t('firstname'),
          value: user.firstname
        },
        {
          key: 'email',
          title: this.$t('email'),
          value: user.email
        },
        {
          key: 'phoneNumber',
          title: this.$t('phone-number'),
          value: user.phoneNumber
        },
        {
          key: 'jobFunction',
          title: this.$t('job-function'),
          type: 'list',
          value: user.jobFunction
        },
        {
          key: 'role',
          title: this.$t('roles'),
          type: 'options',
          value: user.role
        },
        {
          key: 'isSso',
          title: this.$t('sso'),
          type: 'toggle',
          value: user.isSso
        }
      ]

      this.infos = infos
    },
    initTables () {
      const { user, entities, clients } = this
      
      const tables = [
        {
          key: 'clients',
          title: this.$tc('client', 2),
          buttons: [
            {
              key: 'add',
              name: this.$ct('clients.add'),
              on: {
                click: async () => {
                  const clients = await this.fetchClients()

                  this.addClient.list = clients.filter((client) => !this.clients.find(({ clientId }) => clientId === client.id))

                  this.addClient.display = true
                }
              }
            }
          ],
          headers: [
            {
              key: 'name',
              title: this.$t('name')
            },
            {
              key: 'show',
              type: 'action',
              name: this.$ct('display'),
              to (client) {
                return '/clients/' + client.id + '?from_path=/users/' + user.id
              }
            },
            {
              key: 'delete',
              type: 'action',
              name: this.$ct('delete'),
              classList: 'text-indigo-600 hover:text-indigo-900 cursor-pointer',
              on: {
                title: ({ client }) => this.$ct('clients.delete', { name: client.name }),
                yes: async (client) => {
                  this.onClickDeleteClient(client.id)
                }
              }
            }
          ],
          rows: clients.map((client) => {
            return {
              id: client.clientId,
              values: [
                {
                  key: 'name',
                  value: client.name
                }
              ],
              client
            }
          })
        },
        {
          key: 'entities',
          title: this.$tc('entity', 2),
          buttons: [
            {
              key: 'add',
              name: this.$ct('entities.add'),
              on: {
                click: async () => {
                  // en attente du GET /entity en donnant plusieurs clientIds

                  // const entities = await this.fetchEntities()

                  // this.addEntity.list = entities.filter((entity) => !this.entities.find(({ entityId }) => entityId === entity.id))

                  this.addEntity.display = true
                }
              }
            }
          ],
          headers: [
            {
              key: 'name',
              title: this.$t('name')
            },
            {
              key: 'show',
              type: 'action',
              name: this.$ct('display'),
              to (entity) {
                return '/entities/' + entity.id + '?from_path=/users/' + user.id
              }
            }
          ],
          rows: entities.map((entity) => {
            return {
              id: entity.entityId,
              values: [
                {
                  key: 'name',
                  value: entity.name
                }
              ],
              entity
            }
          })
        }
      ]

      this.tables = tables
    },
    async onSave ({ originalValues, newValues }) {
      try {
        const { user } = this

        const data = {
          ...newValues
        }

        if (data.role) {
          await this.updateRole(user.id, data.role)

          originalValues.role = data.role

          delete data.role
        }

        if (!this._isEmpty(data)) {
          await this.updateUser(user.id, data)
        }

        this.$notify(this.$ct('notification.update-success'))
      } catch (err) {
        this.$notify.error()
      }
    },
    async onSaveAddClient () {
      try {
        const { user, addClient, clientsTable, entitiesTable } = this
        
        const { client: selectedClient } = addClient.selected

        await this.addClientToUser(user.id, selectedClient.id)

        const { entities } = await this.fetchClient(selectedClient.id)

        clientsTable.rows.push({
          id: selectedClient.id,
          values: [
            {
              key: 'name',
              value: selectedClient.name
            }
          ],
          client: selectedClient
        })

        entitiesTable.rows.push(...entities.map((entity) => {
          return {
            id: entity.id,
            values: [
              {
                key: 'name',
                value: entity.name
              }
            ],
            entity
          }
        }))

        addClient.selected = {}
        addClient.display = false

        this.$notify(this.$ct('notification.update-success'))
      } catch (err) {
        this.$notify.error()
      }
    },
    async onClickDeleteClient (clientId) {
      try {
        const { user, clientsTable, entitiesTable } = this

        await this.deleteClientToUser(user.id, clientId)

        this.clients = this.clients.filter(client => client.clientId !== clientId)
        clientsTable.rows = clientsTable.rows.filter(({ id }) => id !== clientId)
        this.entities = this.entities.filter(entity => entity.clientId !== clientId)
        entitiesTable.rows = entitiesTable.rows.filter(({ entity }) => entity.clientId !== clientId)

        this.$notify(this.$ct('notification.update-success'))
      } catch (err) {
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>