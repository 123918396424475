export default {
  'account': 'account | accounts',
  'account-name': 'account name',
  'account-options': 'account options',
  'account-type': 'account type',
  'actions': 'actions',
  'activate': 'activate',
  'active-last-week': 'active last week',
  'active-last-month': 'active last month',
  'add-user': 'add a user',
  'address': 'address',
  'all-entities': 'all entities',
  'and': 'and',
  'assignment': 'assignment | assignments',
  'back': 'back',
  'back-to-menu': 'back to menu',
  'balance': 'balance',
  'balancing': 'balancing',
  'bank': 'bank | banks',
  'bank-account': 'bank account | banks accounts',
  'budget': 'budget',
  'business-segment': 'business segment',
  'close': 'close',
  'cancel': 'cancel',
  'caption': 'caption',
  'category': 'category | categories',
  'cash-in': 'cash in',
  'cash-only': 'cash only',
  'cash-out': 'cash out',
  'cash-variation': 'cash variation',
  'cashflow': 'direct cashflow',
  'cashlab-back-office': 'back office of cashlab',
  'child': 'child',
  'children': 'children',
  'classic-account-type': '"classic" account',
  'clear': 'delete',
  'clearance-balance': 'balance clearance',
  'client': 'client | clients',
  'closing-balance': 'closing balance',
  'color': 'color | colors',
  'comment': {
    't': 'comment',
    'add': 'add comment',
    'history': 'comment history',
    'write': 'write your comment'
  },
  'configure': 'configure',
  'connection': 'connect',
  'country': 'country',
  'create': 'create',
  'create-account': 'create account',
  'created-at': 'created on {date}',
  'create-a-ticket': 'create a ticket',
  'create-your-account': 'create your account',
  'currency': 'currency | currencies',
  'date': 'date',
  'day': 'day | days',
  'delete': 'delete',
  'description': 'description',
  'discard-changes': 'cancel changes.',
  'disconnect': 'disconnect',
  'display': 'display',
  'display-type': 'display type',
  'download': 'download',
  'duration': 'duration',
  'duplicate': 'duplicate',
  'duplication': 'duplication',
  'duplication-type': 'type of duplication',
  'dutch': 'dutch',
  'drilldown': 'drilldown',
  'edition': 'edition',
  'email': 'email',
  'end': 'end',
  'end-of-subscription': 'end of subscription',
  'english': 'english',
  'entity': 'entity | entities',
  'entity-name': 'name',
  'exploitation': 'mapping',
  'faq': 'FAQ',
  'fetching-data': 'fetching data',
  'firstname': 'first name',
  'fiscal-start': 'fiscal start',
  'forecast': 'forecast',
  'free-account-type': '"free" account',
  'french': 'french',
  'german': 'german',
  'graphic': 'graphic | graphics',
  'hypothesis': 'hypothesis',
  'import': 'import',
  'informations': 'information',
  'initial': 'initial',
  'initial-balance': 'initial balance',
  'italy': 'italian',
  'job-function': 'title in the company',
  'keyword': 'keyword | keywords',
  'language': 'language',
  'lastname': 'last name',
  'links': 'links',
  'list': 'list | lists',
  'login': 'login',
  'main-entity': 'main entity',
  'module': 'module | modules',
  'month': 'month | months',
  'more-about': 'read more',
  'multiple-choice': 'multiple choice',
  'name': 'name',
  'need-help': 'need help ?',
  'new-password': 'new password',
  'next': 'following',
  'night': 'night',
  'new-version': 'new version available',
  'no': 'no',
  'no-option': 'no option',
  'none': 'none',
  'no-currency': 'no currency',
  'no-data': 'no data',
  'no-result': 'no result',
  'not-found': 'not found',
  'of': 'of',
  'off-pl': 'off P&L',
  'on': 'on',
  'only-for': 'only for {name}',
  'opening-balance': 'opening balance',
  'operation': 'operation | operations',
  'or': 'or',
  'obligatory': 'mandatory',
  'page': 'page',
  'password': 'password',
  'parameters': 'parameters',
  'period-start': 'period start',
  'period-end': 'period end',
  'phone-number': 'telephone number',
  'pl': 'P&L',
  'previous': 'previous',
  'quarter': 'quarter',
  'required-fields': 'required fields',
  'real': 'actual',
  'really': 'really',
  'refine': 'refine',
  'remaining-balance': 'remaining balance',
  'distribution': 'distribution',
  'reportings': 'reportings',
  'restrictions': 'restrictions',
  'restriction-entities': 'entity restriction (multiple choice)',
  'retype-password': 'retype password',
  'roles': 'roles',
  'row': 'row | rows',
  'rules': 'cash laws',
  'salaries': 'salaries',
  'sandbox-account-type': '"sandbox" account',
  'save': 'save',
  'search': 'search',
  'select-by-default': 'select by default',
  'send': 'send',
  'send-mail': 'send e-mail',
  'semester': 'semester',
  'scenarios': 'scenarios',
  'scenarios-list': 'scenarios list',
  'set-up': 'set up',
  'settings': 'settings',
  'statement': 'cashflow statement',
  'statistics': 'statistics',
  'start': 'start',
  'schedule': 'schedule',
  'schedule-percent': 'schedule in %',
  'show-history': 'show history',
  'siret': 'siret',
  'spanish': 'spanish',
  'subscription': 'subscription',
  'sso': 'SSO',
  'swedish': 'swedish',
  'title': 'title',
  'theme': 'theme',
  'third-party-code': 'third-party code',
  'third-party-name': 'third-party name',
  'to': 'to',
  'to-date': 'as of date',
  'total': 'total',
  'transaction': 'transaction | transactions',
  'trial-end-date': 'end date of trial period',
  'undefined': 'undefined',
  'unit': 'unit',
  'unmapped': 'unmapped',
  'update': 'update',
  'updated-on': 'updated on',
  'use-by-default': 'use by default',
  'use-setting': 'use setting',
  'user': 'user | users',
  'username-email': 'login or email',
  'validate': 'validate',
  'vat': 'VAT',
  'variance': 'variance',
  'variation': 'variation',
  'wait': 'wait',
  'without-values': 'without values',
  'wording': 'name',
  'year': 'year',
  'yes': 'yes',
  'your-profil': 'your profile',
  '?': '?',
  '!': '!',
  ':': ':'
}
