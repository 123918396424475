<template>
  <ui-information-card
    v-if="display"
    :infos="infos"
    :statistics="statistics"
    :tables="table.list"
    @save="onSave"
  >
    <template #title>
      {{ $t('clients.informations', { name: client.name }) }}
    </template>
    <template #subtitle>
      {{ $t('created-at', { date: getClientCreatedAt(client) }) }}
    </template>
  </ui-information-card>
</template>

<script>
import fileSaver from 'file-saver'

import authMixin from '../assets/mixins/auth'
import clientsMixin from '../assets/mixins/clients'
import entitiesMixin from '../assets/mixins/entities'
import usersMixin from '../assets/mixins/users'
import reportingMixin from '../assets/mixins/reporting'

import UiInformationCard from '../components/ui-information-card.vue'

export default {
  title () {
    return this.$ctc('client', 1)
  },
  mixins: [authMixin, clientsMixin, entitiesMixin, usersMixin, reportingMixin],
  components: {
    UiInformationCard
  },
  data () {
    return {
      display: false,
      client: {},
      users: [],
      infos: [],
      statistics: [],
      table: {
        list: [],
        user: {},
        entity: {}
      }
    }
  },
  computed: {
    clientId () {
      return this.$route.params.id
    },
    hasClient () {
      return !this._isEmpty(this.client)
    },
    nameInfo () {
      return this.getInfo('name')
    },
    hasSubscriptionInfo () {
      return this.getInfo('hasSubscription')
    },
    subscribeEndDateInfo () {
      return this.getInfo('subscribeEndDate')
    }
  },
  async created () {
    try {
      this.client = await this.fetchClient(this.clientId)

      this.users = this.getUsersByClientId(this.clientId)

      this.initInfos()
      // this.initStatistics()
      this.initTables()

      this.display = true
    } catch (err) {
      this.$notify.error()
    }
  },
  methods: {
    getInfo (key) {
      return this.infos.find(info => info.key === key)
    },
    initInfos () {
      const { client } = this

      // const momentSubscribeEndDate = this.$moment(client.subscribeEndDate, 'DD-MM-YYYY')

      // pas de feature de subscription pour le moment
      
      const infos = [
        {
          key: 'name',
          title: this.$t('name'),
          value: client.name
        },
        {
          key: 'isSso',
          title: this.$t('sso'),
          type: 'toggle',
          value: client.isSso,
          disabled: true
        }
        // {
        //   key: 'hasSubscription',
        //   title: this.$t('subscription'),
        //   type: 'toggle',
        //   disabled: client.hasSubscription === false,
        //   value: client.hasSubscription
        // },
        // {
        //   key: 'subscribeEndDate',
        //   title: this.$t('end-of-subscription'),
        //   type: 'month-year',
        //   month: momentSubscribeEndDate.format('M'),
        //   year: momentSubscribeEndDate.format('YYYY')
        // }
      ]

      this.infos = infos
    },
    initStatistics () {
      const { client } = this

      const modulesUsePercentageStatistics = this.getModulesUsePercentageStatistics(client)
      
      const statistics = [
        {
          key: 'users',
          title: this.$tc('user', 2),
          values: [
            {
              key: 'total',
              name: this.$t('total'),
              value: client.statistics.users.total
            },
            {
              key: 'activeLastWeek',
              name: this.$t('active-last-week'),
              value: client.statistics.users.activeLastWeek
            },
            {
              key: 'activeLastMonth',
              name: this.$t('active-last-month'),
              value: client.statistics.users.activeLastMonth
            }
          ]
        },
        {
          key: 'modules',
          title: this.$tc('module', 2),
          values: modulesUsePercentageStatistics
        }
      ]

      this.statistics = statistics
    },
    initTables () {
      const { client, users } = this

      const entityRows = client.entities.map((entity) => {
        return {
          id: entity.id,
          values: [
            {
              key: 'name',
              value: entity.name
            },
            {
              key: 'siret',
              value: entity.siret
            },
            {
              key: 'userCount',
              value: entity.userCount
            }
          ],
          entity
        }
      })

      const userRows = users.map((user) => {
        const hasFirstNameOrLastName = !!user.lastname || !!user.firstname

        return {
          id: user.id,
          values: [
            {
              key: 'lastnameFirstname',
              value: hasFirstNameOrLastName ? (user.lastname || '') + ' ' + (user.firstname || '') : '-'
            },
            {
              key: 'entityCount',
              value: user.entityCount
            }
          ],
          user,
          client
        }
      })

      this.table.user = {
        key: 'users',
        title: this.$tc('user', 2),
        buttons: [
          {
            key: 'add',
            to: '/users/create?client_id=' + client.id,
            name: this.$ct('users.create')
          }
        ],
        headers: [
          {
            key: 'lastnameFirstname',
            title: this.$ct('lastname') + ' ' + this.$ct('firstname')
          },
          {
            key: 'entityCount',
            title: this.$ctc('entity', 2)
          },
          {
            key: 'show',
            type: 'action',
            name: this.$ct('display'),
            to (user) {
              return '/users/' + user.id + '?from_path=/clients/' + client.id
            }
          },
          {
            // if ({ user }) {
            //   // refactor
            //   const isFirstUser = client.userIds.findIndex(id => id === user.id) === 0

            //   return !isFirstUser
            // },
            key: 'delete',
            type: 'action',
            name: this.$ct('delete'),
            on: {
              title: ({ user }) => {

                const name = user.name || ''
                const firstname = user.firstname || ''
                const lastname = user.lastname || ''

                const nameText = name + ' ' + firstname + ' ' + lastname

                return this.$ct('users.delete', { name: nameText }
              )},
              yes: async({ user, client }) => {
                // mapping

                this.deleteUser(user.id, client.id)

                this.removeUser(user.id)

                this.removeUserFromTable(user.id)

                this.updateEntitiesUserCount()

                // translation
                this.$notify({ type: 'success', text: this.$t('users.delete-success', { name: user.firstname || '' + ' ' + user.lastname || '' }) })
              }
            }
          }
        ],
        rows: userRows
      }

      this.table.entity = {
        key: 'entities',
        title: this.$tc('entity', 2),
        buttons: [
          {
            key: 'add',
            to: '/entities/create?client_id=' + client.id,
            name: this.$ct('entities.create')
          },
          {
            key: 'duplication',
            to: '/entities/duplication?client_id=' + client.id,
            name: this.$ct('duplication') + '...'
          },
          {
            key: 'entityExport',
            type: 'action',
            name: this.$ct('clients.export-entity'),
            on: {
              click: async () => {
                try {
                  const { getCurrentUserId, clientId } = this
                  const { headers, data } = await this.getEntityFromClientReporting({ clientId , userId: getCurrentUserId })
                  const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' })
                  const disposition = headers['content-disposition']
                  const regex = /filename=(.*)/gm
                  const matches = regex.exec(disposition)
  
                  fileSaver.saveAs(blob, decodeURIComponent(matches[1]))
                } catch {
                  this.$notify.error()
                }
              }
            }
          }
        ],
        headers: [
          {
            key: 'name',
            title: this.$ct('name')
          },
          {
            key: 'siret',
            title: this.$ct('siret')
          },
          {
            key: 'userCount',
            title: this.$ctc('user', 2)
          },
          {
            key: 'show',
            type: 'action',
            name: this.$ct('display'),
            to (entity) {
              return '/entities/' + entity.id + '?from_path=/clients/' + client.id
            }
          },
          {
            if ({ entity }) {
              const isTheGroupEntity = entity.isGroup

              return !isTheGroupEntity
            },
            key: 'delete',
            type: 'action',
            name: this.$ct('delete'),
            on: {
              title: ({ entity }) => this.$ct('entities.delete', { name: entity.name }),
              yes: async ({ entity }) => {
                try {
                  this.deleteEntity(entity.id)
                  // mapping

                  this.removeEntity(entity.id)

                  this.removeEntityFromTable(entity.id)

                  this.updateUsersEntityCount()

                  this.$notify({ type: 'success', text: this.$t('entities.delete-success', { name: entity.name }) })
                } catch (error) {
                  this.$notify.error()
                }
              }
            }
          }
        ],
        rows: entityRows
      }
      
      // translation
      const tables = [
        this.table.user,
        this.table.entity
      ]

      this.table.list = tables
    },
    removeEntityFromTable (entityId) {
      this.table.entity.rows = this.table.entity.rows.filter(({ id }) => id !== entityId)
    },
    removeUserFromTable (userId) {
      this.table.user.rows = this.table.user.rows.filter(({ id }) => id !== userId)
    },
    updateUsersEntityCount () {
      this.table.user.rows.forEach(({ values, user }) => {
        const entityCountValue = values.find(({ key }) => key === 'entityCount')

        entityCountValue.value = user.entityIds.length
      })
    },
    updateEntitiesUserCount () {
      this.table.entity.rows.forEach(({ values, entity }) => {
        const userCountValue = values.find(({ key }) => key === 'userCount')

        userCountValue.value = entity.userIds.length
      })
    },
    async onSave ({ originalValues, newValues }) {
      try {
        const { subscribeEndDateInfo, hasSubscriptionInfo } = this
        
        const clientId = this.client.id

        if (newValues.hasSubscription === false) {
          hasSubscriptionInfo.disabled = true

          // reset subscribeEndDate month and year picker
          subscribeEndDateInfo.month = ''
          subscribeEndDateInfo.year = ''
        }

        if (newValues.subscribeEndDate) {
          // merge month and year to be sure there is both values
          newValues.subscribeEndDate = {
            ...originalValues.subscribeEndDate,
            ...newValues.subscribeEndDate
          }

          hasSubscriptionInfo.value = true
          hasSubscriptionInfo.disabled = false

          // prevent isDifferent form for hasSubscription
          originalValues.hasSubscription = true
        }

        const { data: allGood } = await this.updateClient(clientId, newValues)

        if (!allGood) {
          throw new Error('Something got wrong.')
        }

        this.$notify(this.$ct('notification.update-success'))
      } catch {
        this.$notify.error()
      }
    },
    onClickInfo (info) {
      info.isEditing = true

      setTimeout(() => {
        const input = document.querySelector('#info-input-' + info.key)

        if (input) {
          input.focus()
          input.select()
        }
      })
    },
    onBlurInfoInput (info) {
      info.isEditing = false
    }
  }
}
</script>

<style>

</style>